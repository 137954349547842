import React, { Component } from "react"
import PropTypes from "prop-types"

import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import { Grid, Row, Col } from "react-flexbox-grid"

import { isMobile } from "../../utils/responsive"
import * as cookie from "../../utils/cookie"

import s from "./HeaderStyled"

import HeadlineComponent from "./headline/HeadlineComponent"
import { ConnectComponent } from "./connect/ConnectComponent"

class HeaderContainer extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    const { t } = this.props

    return (
      <s.HeaderContainer>
        <Grid fluid>
          <Row middle="xs">
            <Col xs={isMobile ? 6 : 3}>
              <Row center="xs">
                <a
                  href={`http://prostotv.com${
                    cookie.get("language") === "ru" ? "/ru" : ""
                  }`}
                >
                  <s.Logo
                    src="/logo.png"
                    alt={t("Header_Logo")}
                  />
                </a>
              </Row>
            </Col>

            <Col xs={isMobile ? 3 : 6}>
              <Row center="xs">
                <HeadlineComponent />
              </Row>
            </Col>

            <Col xs={isMobile ? 3 : 3}>
              <Row center="xs">
                <ConnectComponent />
              </Row>
            </Col>
          </Row>
        </Grid>
      </s.HeaderContainer>
    )
  }
}

HeaderContainer.propTypes = {
  t: PropTypes.func.isRequired,
}

export default compose(
  withTranslation()
)(HeaderContainer)
