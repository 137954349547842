// import _ from "lodash"
import Immutable from "seamless-immutable"
import * as types from "./types"

const initialState = Immutable({
  plan: null
})

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.PLAN_SELECTED:
      return state.merge({
        plan: action.plan
      })
    case types.CHANGE_PERIOD: {
      return Immutable.setIn(state, ["plan", "period", "id"], action.id)
    }
    default:
      return state
  }
}

// selectors
