import * as types from "./types"

const selectPlanAction = (plan) => ({ type: types.PLAN_SELECTED, plan })

const changePeriodAction = (id) => ({ type: types.CHANGE_PERIOD, id })

const changeTotalPriceAction = (val) => ({ type: types.CHANGE_TOTAL_PRICE, val })

export {
  selectPlanAction,
  changePeriodAction,
  changeTotalPriceAction
}
