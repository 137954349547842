import _ from "lodash"

import React, { Component } from "react"
import PropTypes from "prop-types"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import { prices } from "../../../config/subscription"

import {
  changePeriodAction
} from "../../../store/subscription/actions"

import {
  checkLoginAction,
  transactionCompleteAction,
  getIpAction,
} from "../../../store/pay/actions"

import Button from "../../common/styled/ButtonStyled"

import s from "./ConstructorStyled"

import Account from "./Account"
import Checkout from "./Checkout"

import { accountModel } from "./ConstructorModel"

class ConstructorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accounts: [{ ...accountModel }],
      checkout: {
        description: ""
      },
      errors: {
        email: {
          isValid: false
        }
      },
      formValid: false
    }
  }

  componentDidMount() {
    const element = document.getElementById("constructor-container")
    element.scrollIntoView({ behavior: "smooth" })
  }

  eventChangeDevice = (id, device) => {
    const { accounts } = this.state

    const account = accounts.find((it) => it.id === id)

    account.device = device
    delete account.login
    delete account.mac

    this.setState({ accounts })
  }

  eventAddAccount = () => {
    const { accounts } = this.state

    accounts.push({
      ..._.cloneDeep(accountModel),
      error: {},
      id: accounts.length,
    })

    this.setState(
      { accounts },
      () => this.validatePayPalButtons()
    )
  }

  eventRemoveAccount = (id) => {
    const { accounts } = this.state

    this.setState(
      { accounts: accounts.filter((account) => account.id !== id) },
      () => this.validatePayPalButtons()
    )
  }

  eventChangeCreateAccount = (id, isCreate) => {
    const { accounts } = this.state

    accounts.find((account) => account.id === id).isCreate = isCreate

    this.setState({ accounts })
  }

  eventChangePeriod = (id) => {
    const { changePeriod } = this.props
    const { checkout } = this.state

    changePeriod(id)

    this.setState({
      checkout: { ...checkout, period: id }
    })
  }

  calculatePrice = () => {
    const { plan } = this.props
    const { accounts, checkout } = this.state
    const { differentIp } = checkout

    const periodPrice = prices.period.find(({ id }) => id === plan.period.id).value

    let price = periodPrice * accounts.length

    if (!differentIp && accounts.length > 1) {
      price -= periodPrice * 0.5
    }

    if (!differentIp && accounts.length > 2) {
      price -= periodPrice * 0.7
    }

    return price
  }

  eventAccountChangeText = (id, name, value) => {
    const { accounts } = this.state

    const account = accounts.find((a) => a.id === id)

    account[name] = value
    account.error.message = "Account_Processing"
    account.error.isValid = false
    account.error.processing = true

    this.setState({ accounts, formValid: false })
  }

  eventCheckoutChangeText = (name, value) => {
    const { checkout } = this.state

    checkout[name] = value

    this.setState({ checkout })
  }

  eventEmailChangeText = (name, value) => {
    const { errors } = this.state

    this.eventCheckoutChangeText(name, value)

    errors.email.message = "Constructor_EmailError"
    errors.email.isValid = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value)

    this.setState(
      { errors },
      () => this.validatePayPalButtons()
    )
  }

  eventLoginChangeText = (id, name, value) => {
    this.eventAccountChangeText(id, name, value)

    window.clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.validateAccounts(id, name, value), 750)
  }

  validateAccounts = async (id, name, value) => {
    const { checkLogin, t } = this.props
    const { accounts, checkout } = this.state
    const account = accounts.find((a) => a.id === id)

    let isValid = true

    if (isValid) {
      isValid = !!(account.login || account.mac)
      if (!isValid) account.error.message = "Constructor_EmptyAccount"
    }

    if (accounts.length > 1 && isValid) {
      const names = accounts
        .filter((f) => f.id !== account.id)
        .map(({ login, mac, device }) => (device === 1 ? mac : login))

      isValid = !names.includes(value)
      if (!isValid) account.error.message = "Constructor_DuplicateAccount"
    }

    if (isValid) {
      this.abortController && this.abortController.abort()
      this.abortController = new AbortController()

      account.checkLogin = await checkLogin(value, account.device, this.abortController.signal)
      if (!account.checkLogin) {
        this.validatePayPalButtons()
        return
      }

      const { exist } = account.checkLogin

      isValid = exist
      if (!isValid) account.error.message = "Account_NotFound"
    }

    if (accounts.length > 1 && isValid) {
      const resellerNames = accounts
        .filter((a) => a.checkLogin)
        .map((a) => a.checkLogin.resellerName)
      const uniqNames = _.uniq(resellerNames)

      isValid = uniqNames.length === 1
      if (!isValid) account.error.message = "Account_MultiPaymentNotAvailable"
    }

    if (accounts.length > 1 && isValid) {
      const connectionDates = accounts
        .filter((a) => a.checkLogin && a.checkLogin.connectionDate)
        .map((a) => a.checkLogin.connectionDate)
      const uniqConnectionDate = _.uniq(connectionDates)

      isValid = uniqConnectionDate.length < 2
      if (!isValid) account.error.message = "Account_MultiPaymentNotAvailable"
    }

    if (isValid) {
      const { endDate } = account.checkLogin
      account.error.message = "Account_EndDate"
      account.error.date = endDate
    }

    account.error.isValid = isValid
    account.error.processing = false

    this.setState(
      { accounts, checkout },
      () => this.validatePayPalButtons()
    )
  }

  validatePayPalButtons = () => {
    const { errors, accounts, checkout } = this.state
    const ips = accounts.filter((f) => f.checkLogin).map((m) => m.checkLogin.ip)

    checkout.differentIp = !(
      ips.length > 1
      /*
        TODO: issue with client dynamic IP
        && ips.every((ip) => ip === ips[0])
      */
    )

    const mergeErrors = { ...errors }
    accounts.forEach((f, i) => { mergeErrors[`account_${i + 1}`] = { isValid: f.error.isValid } })

    const isValid = Object.values(mergeErrors).every((v) => v.isValid)

    this.setState({ formValid: isValid })
  }

  eventCompleteOrder = async ({ id }) => {
    const { transactionComplete, getIp } = this.props
    const { checkout, accounts } = this.state
    const { description, differentIp, email } = checkout

    const { ip } = await getIp()

    transactionComplete({
      id,
      differentIp,
      description,
      ip,
      email,
      names: accounts.map(({ login, mac }) => login || mac).join(" ")
    })
  }

  render() {
    const {
      accounts, checkout, errors, formValid
    } = this.state
    const { plan, transaction, t } = this.props

    return (
      <s.ConstructorContainer id="constructor-container">

        {(transaction && transaction.status) && (
          <s.BlockContainer>
            <s.EndUserDateContainer>
              {t("Constructor_SuccessMessage")}

              <s.WishMessage>{t("Constructor_WishMessage")}</s.WishMessage>
            </s.EndUserDateContainer>
          </s.BlockContainer>
        )}

        {(transaction && !transaction.status) && (
          <s.BlockContainer>
            {transaction.error}
          </s.BlockContainer>
        )}

        {(!transaction) && (
          <>
            {accounts.map((account) => (
              <Account
                {...account}
                countAccounts={accounts.length}
                key={account.id}
                deviceChanged={this.eventChangeDevice}
                accountRemoved={this.eventRemoveAccount}
                loginChanged={this.eventLoginChangeText}
              />
            ))}

            {(accounts.length < 3 && plan.id === 6)
            && (
              <Button.Base center onClick={this.eventAddAccount}>
                {t("Constructor_AddAccount")}
              </Button.Base>
            )}

            <Checkout
              {...{ errors, accounts, formValid }}
              countAccounts={accounts.length}
              period={plan.period.id}
              email={checkout.email}
              totalPrice={this.calculatePrice()}
              periodChanged={this.eventChangePeriod}
              textChanged={this.eventCheckoutChangeText}
              orderCompleted={this.eventCompleteOrder}
              sendOrder={this.eventSendOrder}
              emailChange={this.eventEmailChangeText}
            />

          </>
        )}
      </s.ConstructorContainer>
    )
  }
}

ConstructorContainer.defaultProps = {
  plan: null,
  transaction: null
}

ConstructorContainer.propTypes = {
  plan: PropTypes.shape(),
  transaction: PropTypes.shape({
    status: PropTypes.bool,
    error: PropTypes.string
  }),

  changePeriod: PropTypes.func.isRequired,
  checkLogin: PropTypes.func.isRequired,
  transactionComplete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getIp: PropTypes.func.isRequired,
}

const mapStateToProps = ({ subscription, pay }) => ({
  plan: subscription.plan,
  transaction: pay.transaction,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changePeriod: changePeriodAction,
  checkLogin: checkLoginAction,
  transactionComplete: transactionCompleteAction,
  getIp: getIpAction
}, dispatch)

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ConstructorContainer)
