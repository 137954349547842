import styled, { } from "styled-components"
import color from "../common/styled/ColorStyled"

export default {
  FormContainer: styled.div`
    ${color.Background.Primary};
    ${color.Text.White};
    box-shadow: inset 0px 10px 70px rgba(0,0,0,0.2), inset 0 30px 15px -30px rgba(0,0,0,0.3);
  `,
  InfoContainer: styled.div`
    padding: 30px 0;
    margin: 0 auto;
    text-align: center;
  `,
  Title: styled.div`
    font-size: 40px;
    margin-bottom: 30px;
  `,
  Description: styled.div`
    width: 600px;
    margin: 0 auto;
  `,
}
