import styled, { css } from "styled-components"

import { isDesktop } from "../../utils/responsive"
import color from "../common/styled/ColorStyled"

export default {
  AppContainer: styled.div`
    background-color: #ffffff;
    margin: 0 auto;
  `,
  Background: styled.div`
    ${color.Background.Primary};
    ${color.Text.White};
    box-shadow: inset 0px 15px 70px #de9900;
  `,
}
