import styled, { css } from "styled-components"

const focus = css`
  box-shadow: 0 3px 8px rgba(0,0,0,0.2), inset 0 0 20px 5px rgba(255,255,255,0.3);
  background-color: #ffb000;
  color: rgba(255,255,255,0.85);
`

export default {
  Base: styled.button`
    display: block;
    padding: 12px 34px;
    background-color: #ffb000d4;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 8px;
    border-style: none;
    outline: none;
    color: rgba(255,255,255,0.85);
    font-weight: bold;
    cursor: pointer;
    &::-moz-focus-inner {
      border: 0;
    }
    &:active {
      ${focus};
      background-color: #ffb000d4;
    }

    ${({ center }) => center && css`
      margin: 0 auto;
    `}
  `,
  Close: styled.a`
    position: absolute;
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    &:active {
      opacity: 1;
    }
    &:before,:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 26px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  `,
  Focus: focus
}
