import PropTypes from "prop-types"

export const accountModel = {
  id: 0,
  device: 3,
  isCreate: false,
  checkLogin: null,
  error: {
    isValid: false,
    message: undefined,
    processing: false
  }
}

export const accountPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  device: PropTypes.number.isRequired,
  isCreate: PropTypes.bool.isRequired,
  checkLogin: PropTypes.shape({}),
  error: PropTypes.shape({})
})
