import React, {
  useState
} from "react"

import { useTranslation } from "react-i18next"
import { Grid, Row, Col } from "react-flexbox-grid"

import { isDesktop, isLandscape } from "../../utils/responsive"

import s from "./SpeedStyled"
import Card from "./Card"

const serverData = [
  {
    id: 1,
    name: "Speed_Server1",
    subdomain: "de1"
  },
  {
    id: 2,
    name: "Speed_Server2",
    subdomain: "ge2"
  },
  {
    id: 3,
    name: "Speed_Server3",
    subdomain: "fr1"
  },
  {
    id: 4,
    name: "Speed_Server4",
    subdomain: "ca1"
  },
  {
    id: 5,
    name: "Speed_Server5",
    subdomain: "de1"
  }
]

const recommendResolution = [
  {
    value: 4,
    name: "SD (до 720p)"
  },
  {
    value: 10,
    name: "Full HD (1080p)"
  },
  {
    value: 25,
    name: "Ultra HD (4K)",
  }
]

function SpeedContainer(props) {
  const { t } = useTranslation()

  const [selected, setSelected] = useState(0)
  const [cardTabSelected, setCardTabSelected] = useState([])
  const [cardTabSpeed, setCardTabSpeed] = useState([])
  const [lock, setLock] = useState(false)

  async function handleSelectCard(id) {
    if (lock) {
      return
    }

    setLock(true)
    setSelected(id)

    cardTabSelected[id] = 1
    setCardTabSelected(cardTabSelected)

    cardTabSpeed[id] = 0
    setCardTabSpeed([...cardTabSpeed])

    const server = serverData.find((data) => data.id === id)

    const start = new Date().valueOf()
    const response = await fetch(`https://${server.subdomain}.prostotele.vision/1000mb?d=${start}`)
    let loaded = 0

    const values = []

    new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          loaded += value.byteLength

          const now = new Date().valueOf()
          const fromStart = now - start
          if (fromStart > 12500) {
            reader.cancel()

            setLock(false)

            cardTabSelected[id] = 2
            setCardTabSelected([...cardTabSelected])
            return
          }

          values.push({
            time: now,
            speed: loaded / fromStart
          })
          const lastN = values.filter(
            (v) => (now - v.time) < Math.min(fromStart, 2000)
          )

          const sum = lastN.reduce((a, b) => a + b.speed, 0)
          const avg = (sum / lastN.length)

          const current = Math.ceil(((avg * 1000 * 8) / 1000000) * 10) / 10
          cardTabSpeed[id] = current
          setCardTabSpeed([...cardTabSpeed])

          controller.enqueue(value)
        }
        controller.close()
      },
    })
  }

  return (
    <s.SpeedContainer>
      {(t("ChoosePlan_Title") !== "")
      && (
        <s.InfoContainer>
          <s.Title>{t("ChoosePlan_Title")}</s.Title>
          <span>
            {t("ChoosePlan_Description")}
          </span>
        </s.InfoContainer>
      )}

      <s.SpeedCardContainer>
        <Grid fluid>
          <Row>
            {serverData && serverData.map((it) => (
              <Col key={it.id} xs={isDesktop || isLandscape ? 4 : 12}>
                <Card
                  selected={it.id === selected}
                  selectCard={() => handleSelectCard(it.id)}
                  data={it}
                  tabSelected={cardTabSelected}
                  tabSpeed={cardTabSpeed}
                  lock={lock}
                  recommendResolution={recommendResolution}
                />
              </Col>
            ))}
          </Row>
        </Grid>
      </s.SpeedCardContainer>
    </s.SpeedContainer>
  )
}

SpeedContainer.propTypes = {
}

export default SpeedContainer
