import React from "react"
import PropTypes from "prop-types"

import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import s from "./HeadlineStyled"

const HeadlineComponent = ({
  t
}) => (
  <s.HeadlineComponent>
    <s.Title>{t("Headline_Title")}</s.Title>
    <div>
      {t("Headline_Description")}
    </div>
  </s.HeadlineComponent>
)

HeadlineComponent.propTypes = {
  t: PropTypes.func.isRequired
}

export default compose(
  withTranslation()
)(HeadlineComponent)
