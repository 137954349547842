import React, { Component } from "react"
import PropTypes from "prop-types"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import { Grid, Row, Col } from "react-flexbox-grid"

import { selectPlanAction } from "../../store/subscription/actions"
import { isDesktop } from "../../utils/responsive"

import s from "./ChoosePlanStyled"
import Card from "./Card"

class ChoosePlanContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pricesData: [
        {
          id: 1,
          name: "10",
          period: {
            id: 1,
            name: "Checkout_RangePeriod1"
          },
          currency: true
        },
        {
          id: 2,
          name: "30",
          period: {
            id: 3,
            name: "Checkout_RangePeriod3"
          },
          currency: true
        },
        {
          id: 3,
          name: "60",
          period: {
            id: 6,
            name: "Checkout_RangePeriod6"
          },
          currency: true
        },
        {
          id: 4,
          name: "100",
          period: {
            id: 7,
            name: "Checkout_RangePeriod7"
          },
          currency: true
        },
        {
          id: 5,
          name: "200",
          period: {
            id: 8,
            name: "Checkout_RangePeriod8"
          },
          currency: true
        },
        {
          id: 6,
          name: "ChoosePlan_Custom",
          period: {
            id: 1,
          },
          currency: false
        },
      ],
      selected: 0
    }
  }

  selectCard = (id) => {
    const { selectPlan } = this.props
    const { pricesData } = this.state

    this.setState({ selected: id })

    selectPlan(pricesData.find((data) => data.id === id))
  }

  render() {
    const { t } = this.props
    const { pricesData, selected } = this.state

    return (
      <s.ChoosePlanContainer>
        {(t("ChoosePlan_Title") !== "")
        && (
          <s.InfoContainer>
            <s.Title>{t("ChoosePlan_Title")}</s.Title>
            <span>
              {t("ChoosePlan_Description")}
            </span>
          </s.InfoContainer>
        )}

        <s.ChooseCardContainer>
          <Grid fluid>
            <Row>
              {pricesData && pricesData.map((it) => (
                <Col key={it.id} xs={isDesktop ? 4 : 6}>
                  <Card
                    selected={it.id === selected}
                    selectCard={this.selectCard}
                    data={it}
                  />
                </Col>
              ))}
            </Row>
          </Grid>
        </s.ChooseCardContainer>
      </s.ChoosePlanContainer>
    )
  }
}

ChoosePlanContainer.propTypes = {
  selectPlan: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  selectPlan: selectPlanAction
}, dispatch)

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChoosePlanContainer)
