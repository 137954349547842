import styled, { } from "styled-components"

export default {
  HeaderContainer: styled.div`
    padding: 40px 0;
  `,
  Logo: styled.img`
    opacity: 0.7;
  `,
}
