import styled, { css } from "styled-components"


import { isDesktop } from "../../../utils/responsive"
import shadow from "../../common/styled/ShadowStyled"
import color from "../../common/styled/ColorStyled"

export default {
  ConstructorContainer: styled.div`
    padding: 25px 0;
    ${color.Background.White};
    ${shadow.ContainerTop};
  `,
  BlockContainer: styled.div`
    position: relative;
    padding: 36px;
    ${shadow.Box};
    border-radius: 10px;
    margin: 25px auto;
    ${color.Text.Dark};
    background-color: white;

    ${isDesktop && css`
      width: 580px;
    `}
  `,
  Price: styled.div`
    margin: 16px 0 24px 0;
    text-align: right;
    
    &>sup {
      font-size: 28px;
      vertical-align: top;
      top: 4px;
      position: relative;
    }
    &>span {
      font-size: 60px;
      font-weight: bold;
    }
  `,
  ControlWrapper: styled.div`
    margin-top: 32px;

    ${isDesktop && css`
      display: flex;
    `}
  `,
  PayPalWrapper: styled.div`
    position:relative;
    
    ${({ valid }) => !valid && css`
      filter: grayscale(100%);
    `}
  `,
  PayPalOverlay: styled.div`
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  EndUserDateContainer: styled.div`
    font-size: 18px;
    text-align: center;
  `,
  EndUserDateWrapper: styled.div`
    padding: 8px 0;
  `,
  EndUserDate: styled.div`
    font-size: 16px;
    margin: 8px 0px;
  `,
  WishMessage: styled.div`
    margin-top: 8px;
    font-size: 20px;
  `,
}
