import React from "react"
import PropTypes from "prop-types"

import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import s from "./ChoosePlanStyled"

const Card = ({
  data: {
    id, period, currency, name,
  },
  selectCard,
  selected,
  t
}) => (
  <s.CardContainer
    onClick={() => selectCard(id)}
    focus={selected}
  >
    {currency && (
      <s.Period>{period.name && t(period.name).toUpperCase()}</s.Period>
    )}

    <s.Price
      currency={currency}
    >
      {currency && (
        <sup>€</sup>
      )}
      <span>{t(name)}</span>
    </s.Price>

    <s.Buy center focus={selected}>{t("ChoosePlan_Buy")}</s.Buy>
  </s.CardContainer>
)

Card.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    period: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    currency: PropTypes.bool
  }).isRequired,

  selectCard: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  withTranslation()
)(Card)
