import React, { Component } from "react"
import s from "./AppStyled"

import HostEnum from "../../config/host.config"

import HeaderContainer from "../header/HeaderContainer"
// import FeatureContainer from "../feature/FeatureContainer"
import ChoosePlanContainer from "../choose-plan/ChoosePlanContainer"
import FormContainer from "../form/FormContainer"
import SpeedContainer from "../speed/SpeedContainer"
import FooterContainer from "../footer/FooterContainer"

class App extends Component {
  constructor() {
    super()

    this.state = {
    }
  }

  render() {
    return (
      <s.AppContainer>
        <s.Background>
          <HeaderContainer />
          {/* <FeatureContainer /> */}
        </s.Background>

        {HostEnum.PAY === window.location.hostname && (
          <>
            <ChoosePlanContainer />
            <FormContainer />
          </>
        )}

        {HostEnum.SPEED === window.location.hostname && (
          <SpeedContainer />
        )}

        <FooterContainer />
      </s.AppContainer>
    )
  }
}

export default App
