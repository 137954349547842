export const prices = {
  period: [
    { id: 1, name: "Checkout_RangePeriod1", value: 10 },
    { id: 2, name: "Checkout_RangePeriod2", value: 20 },
    { id: 3, name: "Checkout_RangePeriod3", value: 30 },
    { id: 4, name: "Checkout_RangePeriod4", value: 40 },
    { id: 5, name: "Checkout_RangePeriod5", value: 50 },
    { id: 6, name: "Checkout_RangePeriod6", value: 60 },
    { id: 7, name: "Checkout_RangePeriod7", value: 100 },
    { id: 8, name: "Checkout_RangePeriod8", value: 200 }
  ]
}
