import React, { Component } from "react"
import PropTypes from "prop-types"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import s from "./FormStyled"

import ConstructorContainer from "./constructor/ConstructorContainer"

class FormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const { plan, t } = this.props
    return (
      <s.FormContainer id="form-container">
        <s.InfoContainer>
          {(t("Form_Title") !== "")
          && (
            <>
              <s.Title>
                {t("Form_Title")}
              </s.Title>

              <s.Description>
                {t("Form_Description")}
              </s.Description>
            </>
          )}
        </s.InfoContainer>

        {plan != null && (
          <ConstructorContainer />
        )}

      </s.FormContainer>
    )
  }
}

FormContainer.defaultProps = {
  plan: null
}

FormContainer.propTypes = {
  plan: PropTypes.shape(),

  t: PropTypes.func.isRequired,
}

const mapStateToProps = ({ subscription }) => ({
  plan: subscription.plan
})

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(FormContainer)
