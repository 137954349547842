const isDesktop = window.matchMedia("(min-device-width: 1025px)").matches
const isMobile = window.matchMedia("(max-device-width: 1025px)").matches
const isPortrait = window.matchMedia("(orientation: portrait)").matches
const isLandscape = window.matchMedia("(orientation: landscape)").matches
const isRetina = window.matchMedia("(min-resolution: 2dppx)").matches

const Desktop = ({ children }) => (isDesktop ? children : null)
const Mobile = ({ children }) => (isMobile ? children : null)

export {
  isDesktop,
  isMobile,
  isPortrait,
  isLandscape,
  isRetina,
  Desktop,
  Mobile
}
