import styled, { css } from "styled-components"

import { isMobile, isDesktop } from "../../utils/responsive"

import button from "../common/styled/ButtonStyled"
import shadow from "../common/styled/ShadowStyled"
import color from "../common/styled/ColorStyled"
import base from "../common/styled/BaseStyled"

export default {
  SpeedContainer: styled.div`
    padding: 40px 0;
    text-align: center;
    ${shadow.ContainerTop};
    ${color.Background.White};
  `,
  Title: styled.div`
    font-size: 40px;
    margin-bottom: 30px;
  `,
  InfoContainer: styled.div`
    width: 600px;
    margin: 0 auto;
  `,
  SpeedCardContainer: styled.div`
    ${isDesktop && css`
      padding: 35px 100px;
    `}
  `,
  CardContainer: styled.div`
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #ffffff;
    ${shadow.Box};
    cursor: pointer;
    height: 272px;

    ${({ lock }) => lock && css`
      cursor: not-allowed;
    `};

    ${({ focus }) => focus && css`
      ${[...base.Focus, ...shadow.BoxHighlight].join("")};
    `};

    ${isDesktop && css`
      margin: 24px 16px;
    `}

    ${isMobile && css`
      margin: 8px 0px;
    `}
  `,
  Name: styled.div`
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%);
    white-space: nowrap;
    font-size: 36px;

    ${({ bottom }) => bottom && css`
      top: 84%;
      opacity: 0.8;
      font-size: 18px;
    `};
  `,
  Start: styled(button.Base)`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    cursor: inherit;

    ${isDesktop && css`
      bottom: 40px;
    `}

    ${isMobile && css`
      bottom: 24px;
    `}
  `,
  Meter: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
  `,
  Tip: styled.span`
    position: absolute;
    font-size: 20px;
    left: 50%;
    transform: translate(-50%);
    top: 32%;
    width: 264px;
  `,
  Incompatible: styled.span`
    position: absolute;
    font-size: 20px;
    left: 50%;
    transform: translate(-50%);
    top: 40%;
    width: 264px;
  `,
  Resolution: styled.span`
    font-weight: bold;
    position: absolute;
    font-size: 32px;
    left: 50%;
    top: 55%;
    transform: translate(-50%);
    white-space: nowrap;
  `,
  Speed: styled.span`
    font-weight: bold;
    position: absolute;
    font-size: 28px;
    left: 50%;
    top: 20px;
    transform: translate(-50%);
    white-space: nowrap;
  `,
}
