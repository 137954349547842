import React from "react"
import PropTypes from "prop-types"

import { compose } from "recompose"
import { withTranslation } from "react-i18next"

import InputStyled from "../../common/styled/InputStyled"
import AlertStyled from "../../common/styled/AlertStyled"
import s from "./ConstructorStyled"

import { prices } from "../../../config/subscription"
import RangeItem from "../../common/component/RangeItem"
import PaypalContainer from "../../paypal/PaypalContainer"
import { accountPropTypes } from "./ConstructorModel"

const Checkout = ({
  period, totalPrice, email, errors, accounts, formValid,
  textChanged, periodChanged, orderCompleted, emailChange, t
}) => (
  <s.BlockContainer>
    <RangeItem
      name="period"
      text={t(prices.period.find(({ id }) => id === period).name)}
      onChange={periodChanged}
      value={period}
    />

    <s.ControlWrapper>
      <InputStyled.Text
        name="email"
        type="email"
        placeholder="Email"
        onChange={(e) => emailChange(e.target.name, e.target.value)}
      />
      {(!errors.email.isValid && errors.email.message)
      && (
        <AlertStyled.Inline
          severity="error"
        >
          {t(errors.email.message)}
        </AlertStyled.Inline>
      )}
    </s.ControlWrapper>

    <InputStyled.MultilineText
      name="description"
      placeholder={t("Checkout_PlaceholderDescription")}
      onChange={(e) => textChanged(e.target.name, e.target.value)}
    />

    <s.Price>
      <sup>€</sup>
      <span>{totalPrice}</span>
    </s.Price>

    <s.PayPalWrapper
      valid={formValid}
    >
      <PaypalContainer
        {...{
          email, period, accounts, orderCompleted, totalPrice
        }}
      />

      {!formValid && <s.PayPalOverlay />}
    </s.PayPalWrapper>
  </s.BlockContainer>
)

Checkout.defaultProps = {
  email: undefined
}
Checkout.propTypes = {
  countAccounts: PropTypes.number.isRequired,
  period: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  email: PropTypes.string,
  errors: PropTypes.shape({
    email: PropTypes.shape({
      isValid: PropTypes.bool.isRequired,
      message: PropTypes.string
    })
  }).isRequired,
  accounts: PropTypes.arrayOf(accountPropTypes).isRequired,
  formValid: PropTypes.bool.isRequired,

  periodChanged: PropTypes.func.isRequired,
  textChanged: PropTypes.func.isRequired,
  orderCompleted: PropTypes.func.isRequired,
  emailChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  withTranslation()
)(Checkout)
