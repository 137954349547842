import styled, { css } from "styled-components"

import { isMobile, isDesktop } from "../../utils/responsive"

import button from "../common/styled/ButtonStyled"
import shadow from "../common/styled/ShadowStyled"
import color from "../common/styled/ColorStyled"
import base from "../common/styled/BaseStyled"

export default {
  ChoosePlanContainer: styled.div`
    padding: 40px 0;
    text-align: center;
    ${shadow.ContainerTop};
    ${color.Background.White};
  `,
  Title: styled.div`
    font-size: 40px;
    margin-bottom: 30px;
  `,
  InfoContainer: styled.div`
    width: 600px;
    margin: 0 auto;
  `,
  ChooseCardContainer: styled.div`
    ${isDesktop && css`
      padding: 35px 100px;
    `}
  `,
  CardContainer: styled.div`
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #ffffff;
    ${shadow.Box};

    ${({ focus }) => focus && css`
      ${[...base.Focus, ...shadow.BoxHighlight].join("")};
    `};

    ${isDesktop && css`
      height: 272px;
      margin: 24px 16px;
      padding: 50px 0;
    `}

    ${isMobile && css`
      height: 184px;
      margin: 8px 0px;
      padding: 24px 0;
    `}
  `,
  Period: styled.div`
    font-weight: bold;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    
    ${isDesktop && css`
      font-size: 18px;
      top: 40px;
    `}

    ${isMobile && css`
      font-size: 14px;
      top: 24px;
    `}
  `,
  Price: styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -64%);

    &>sup {
      vertical-align: top;
      top: 4px;
      position: relative;

      ${isDesktop && css`
        font-size: 28px;
      `}

      ${isMobile && css`
        font-size: 20px;
      `}
    }
    &>span {
      font-weight: bold;

      ${isDesktop && css`
        font-size: ${({ currency }) => (!currency ? "40px" : "60px")};
      `}

      ${isMobile && css`
        font-size: ${({ currency }) => (!currency ? "20px" : "38px")};
      `}
    }
  `,
  Buy: styled(button.Base)`
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    ${isDesktop && css`
      bottom: 40px;
    `}

    ${isMobile && css`
      bottom: 24px;
    `}
  `,
}
