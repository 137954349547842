import React from "react"
import ReactDOM from "react-dom"
import { createStore, applyMiddleware, combineReducers } from "redux"
import { Provider } from "react-redux"
import thunk from "redux-thunk"

import { StylesProvider } from "@material-ui/styles"

import "./index.css"
import "./utils/i18n.utils"

import App from "./components/app/App"

import * as serviceWorker from "./serviceWorker"
import * as reducers from "./store/reducers"

const store = createStore(combineReducers(reducers), applyMiddleware(thunk))

ReactDOM.render(
  <StylesProvider injectFirst>
    <Provider store={store}>
      <App />
    </Provider>
  </StylesProvider>,
  document.getElementById("root")
)

serviceWorker.unregister()
