import styled, { css } from "styled-components"

export default {
  ConnectContainer: styled.div`
    text-align: left;
  `,
  ItemContainer: styled.div`
    margin: 0 8px;
    display: inline-block;
  `,
  ItemImage: styled.div`
    width: 30px;
    height: 30px;

    ${({ icon }) => icon};
  `,
  ItemLink: styled.a`
    text-decoration: none;
    color: white;
  `,
  TelegramIcon: css`
    background: url('data:image/svg+xml,%3Csvg height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m1.375 7.318c5.369-2.399 8.949-3.98 10.74-4.744 5.114-2.182 6.177-2.561 6.87-2.574.152-.003.493.036.713.22.187.155.238.364.262.511.025.147.055.482.031.744-.277 2.987-1.476 10.235-2.086 13.58-.259 1.416-.767 1.89-1.259 1.937-1.069.101-1.882-.725-2.917-1.422-1.621-1.09-1.636-.811-3.174-1.911-1.376-.983-1.084-2.256-.048-3.36.271-.289 3.799-4.174 3.89-4.573.011-.05.138-.698.03-.796-.107-.098-.606-.101-.72-.075-.163.038-2.447 1.6-6.852 4.686-.735.518-1.401.77-1.997.757-.658-.015-1.923-.382-2.863-.695-1.153-.385-2.07-.588-1.99-1.241.041-.34.498-.688 1.37-1.044z" fill="%23179cde" fill-rule="evenodd"/%3E%3C/svg%3E') no-repeat;
    background-size: 26px;
    background-position-y: 4px;
    opacity: 0.7;
  `,
  MailIcon: css`
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M20%204H4c-1.1%200-1.99.9-1.99%202L2%2018c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2V6c0-1.1-.9-2-2-2zm0%2014H4V8l8%205%208-5v10zm-8-7L4%206h16l-8%205z%22%2F%3E%3C%2Fsvg%3E") no-repeat;
    background-size: 30px;
    opacity: 0.5;
  `,
}
